<template>
  <div class="orders">
    <div v-if="orderType === 1" class="orders-array">
      <div class="orders-bid">
        <div class="orders-bid-header">
          <div class="orders-bid-header-left">
            <div class="bid-green">
              <img
                alt="Buy orders"
                src="@/assets/svg/v2/products/buyOrder.svg"
                class="light-icon"
              />
            </div>
            <p>{{ $t('product_page.orders.buy_orders') }}</p>
          </div>

          <div class="orders-options">
            <div
              class="orders-bid-header-right"
              @click="$emit('checkCreateOrderAgree', 'buy')"
            >
              <img
                alt="Add bid order"
                src="@/assets/svg/v2/portfolio/portfolioLiquor.svg"
                class="themed-img"
              />
              {{ $t('product_page.orders.create_buy_order') }}
            </div>

            <el-popover placement="top" width="300">
              <template #reference>
                <div class="orders-bid-header-right absolute-position">
                  <img
                    alt="Add order alert"
                    src="@/assets/svg/v2/settings/notifications.svg"
                    class="themed-img"
                  />
                  {{ $t('product_page.orders.create_alert') }}

                  <p class="nb-alerts action-light">{{ getCurrentOrdersAlerts.buy }}</p>
                </div>
              </template>

              <div class="popover-create-alert">
                <div class="create-alert-buy-inputs">
                  <el-input
                    v-model="orderAlert.price"
                    style="width: 240px"
                    :placeholder="
                      $t('product_page.orders.order_alert_price_trigger')
                    "
                  />
                  <button @click="createOrderAlert(0)" class="generic-btn">
                    {{ $t('commons.confirm') }}
                  </button>
                </div>
                <p>{{ $t('product_page.orders.create_alert_explain_buy') }}</p>
              </div>
            </el-popover>
          </div>
        </div>

        <div class="orders-bid-title">
          <h5 class="orders-bid-title-multiple"></h5>
          <h5 class="orders-bid-title-orders">{{ $t('commons.orders') }}</h5>
          <h5 class="orders-bid-title-account">
            {{ $t('commons.account_type') }}
          </h5>
          <h5 class="orders-bid-title-quantity">
            {{ $t('commons.quantity') }}
          </h5>
          <h5 class="orders-bid-title-bid">{{ $t('commons.bid') }}</h5>
        </div>

        <div
          v-for="(order_bid, n) in orders(0)"
          :key="n"
          class="orders-bid-body"
          @click="selectOrder(2, order_bid)"
        >
          <div
            :class="{
              pending:
                (order_bid.is_pretrade && order_bid.children.length === 0) ||
                !order_bid.preferences_matched,
            }"
            class="orders-bid-body-unique"
          >
            <el-tooltip
              v-if="!order_bid.preferences_matched"
              class="tooltip"
              effect="dark"
              :content="$t('product_page.orders.not_match_preferences')"
              placement="top"
            >
              <img
                src="@/assets/svg/v2/orders/info.svg"
                class="themed-img"
                alt="Info"
                width="20"
              />
            </el-tooltip>
            <p v-if="order_bid.is_pretrade" class="orders-ask-body-pending">
              <el-icon class="orders-ask-body-buy-pending-icon">
                <Loading />
              </el-icon>
            </p>
            <p
              class="orders-bid-body-multiple"
              @click.stop="order_bid.deploy = !order_bid.deploy"
            >
              <el-icon
                v-if="
                  orders(0).length > 0 &&
                  order_bid.size > 1 &&
                  !order_bid.deploy
                "
                color="white"
              >
                <ArrowRight color="#FF000" />
              </el-icon>
              <el-icon v-if="order_bid.deploy">
                <ArrowDown color="#FF000" />
              </el-icon>
            </p>
            <p class="orders-bid-body-orders green">{{ order_bid.size }}</p>
            <p class="orders-bid-body-account green action-light">
              <img
                v-if="labelUserType(order_bid) == $t('product_page.orders.pro')"
                alt="Pro account"
                src="@/assets/svg/v2/pro.svg"
                class="themed-img"
              />
              <img
                v-else
                alt="Individual account"
                src="@/assets/svg/v2/personnal.svg"
                class="themed-img"
              />
              <span class="hidden-tel">{{ labelUserType(order_bid) }}</span>
            </p>
            <p
              v-if="order_bid.children.length === 1"
              class="orders-bid-body-quantity green"
            >
              {{ order_bid.quantity }}
            </p>
            <p v-else class="orders-bid-body-quantity green">
              {{ returnGlobalQuantity(order_bid) }}
            </p>
            <p class="orders-bid-body-bid green">
              {{ order_bid.price / 100 }} €
            </p>
          </div>
          <!-- Childs orders -->
          <div v-if="order_bid.deploy" class="orders-bid-body-multiple-orders">
            <div
              class="flex-subgroup"
              v-for="(order_bid_child, c) in order_bid.children"
              :key="c"
              @click.stop="selectOrder(1, order_bid_child)"
            >
              <p class="orders-bid-body-multiple"></p>
              <p class="orders-bid-body-orders green">
                {{ order_bid_child.size }}
              </p>
              <p class="orders-bid-body-account green action-light">
                <img
                  v-if="
                    labelUserType(order_bid_child) ==
                    $t('product_page.orders.pro')
                  "
                  alt="Pro account"
                  src="@/assets/svg/v2/pro.svg"
                  class="themed-img"
                />
                <img
                  v-else
                  alt="Individual account"
                  src="@/assets/svg/v2/personnal.svg"
                  class="themed-img"
                />
                <span class="hidden-tel">
                  {{ labelUserType(order_bid_child) }}</span
                >
              </p>
              <p class="orders-bid-body-quantity green">
                {{ order_bid_child.quantity }}
              </p>
              <p class="orders-bid-body-bid green">
                {{ order_bid_child.price / 100 }} €
              </p>
            </div>
          </div>
        </div>

        <div v-if="orders(0).length === 0" class="empty-orders">
          {{ $t('product_page.orders.empty_buy') }}
        </div>
      </div>
      <div class="orders-ask">
        <div class="orders-ask-header">
          <div class="orders-ask-header-left">
            <div class="ask-red">
              <img
                alt="Sell orders"
                src="@/assets/svg/v2/products/sellOrder.svg"
                class="light-icon"
              />
            </div>
            <p>{{ $t('product_page.orders.sell_orders') }}</p>
          </div>

          <div class="orders-options">
            <div
              class="orders-ask-header-right"
              @click="$emit('checkCreateOrderAgree', 'sell')"
            >
              <img
                alt="Add ask order"
                src="@/assets/svg/v2/portfolio/portfolioLiquor.svg"
                class="themed-img"
              />
              {{ $t('product_page.orders.create_sell_order') }}
            </div>

            <el-popover placement="top" width="300">
              <template #reference>
                <div class="orders-ask-header-right absolute-position">
                  <img
                    alt="Add order alert"
                    src="@/assets/svg/v2/settings/notifications.svg"
                    class="themed-img"
                  />
                  {{ $t('product_page.orders.create_alert') }}
                  <p class="nb-alerts action-light">{{ getCurrentOrdersAlerts.sell }}</p>
                </div>
              </template>

              <div class="popover-create-alert">
                <div class="create-alert-sell-inputs">
                  <el-input
                    v-model="orderAlert.price"
                    style="width: 240px"
                    :placeholder="
                      $t('product_page.orders.order_alert_price_trigger')
                    "
                  />
                  <button @click="createOrderAlert(1)" class="generic-btn">
                    {{ $t('commons.confirm') }}
                  </button>
                </div>
                <p>{{ $t('product_page.orders.create_alert_explain_sell') }}</p>
              </div>
            </el-popover>
          </div>
        </div>

        <div class="orders-ask-title">
          <h5 class="orders-ask-title-ask">{{ $t('commons.ask') }}</h5>
          <h5 class="orders-ask-title-quantity">
            {{ $t('commons.quantity') }}
          </h5>
          <h5 class="orders-ask-title-account">
            {{ $t('commons.account_type') }}
          </h5>
          <h5 class="orders-ask-title-orders">{{ $t('commons.orders') }}</h5>
          <h5 class="orders-ask-title-multiple"></h5>
        </div>
        <div
          v-for="(order_ask, n) in orders(1)"
          :key="n"
          class="orders-ask-body"
          @click="selectOrder(2, order_ask)"
        >
          <div
            :class="{
              pending:
                (order_ask.is_pretrade && order_ask.children.length === 0) ||
                !order_ask.preferences_matched,
            }"
            class="orders-ask-body-unique"
          >
            <p class="orders-ask-body-ask red">{{ order_ask.price / 100 }} €</p>
            <p
              v-if="order_ask.children.length === 1"
              class="orders-ask-body-quantity red"
            >
              {{ order_ask.quantity }}
            </p>
            <p v-else class="orders-ask-body-quantity red">
              {{ returnGlobalQuantity(order_ask) }}
            </p>
            <p class="orders-ask-body-account red  action-light">
              <img
                v-if="labelUserType(order_ask) == $t('product_page.orders.pro')"
                alt="Pro account"
                src="@/assets/svg/v2/pro.svg"
                class="themed-img"
              />
              <img
                v-else
                alt="Individual account"
                src="@/assets/svg/v2/personnal.svg"
                class="themed-img"
              />
              <span class="hidden-tel">{{ labelUserType(order_ask) }}</span>
            </p>
            <p class="orders-ask-body-orders red">{{ order_ask.size }}</p>
            <p
              class="orders-ask-body-multiple"
              @click.stop="order_ask.deploy = !order_ask.deploy"
            >
              <el-icon
                v-if="
                  orders(1).length > 0 &&
                  order_ask.size > 1 &&
                  !order_ask.deploy
                "
                color="white"
              >
                <ArrowLeft />
              </el-icon>
              <el-icon v-if="order_ask.deploy">
                <ArrowDown color="#FF000" />
              </el-icon>
            </p>
            <p v-if="order_ask.is_pretrade" class="orders-ask-body-pending">
              <el-icon class="orders-ask-body-sell-pending-icon">
                <Loading />
              </el-icon>
            </p>
            <el-tooltip
              v-if="!order_ask.preferences_matched"
              class="tooltip"
              effect="dark"
              :content="$t('product_page.orders.not_match_preferences')"
              placement="top"
            >
              <img
                src="@/assets/svg/v2/orders/info.svg"
                class="themed-img"
                alt="Info"
                width="20"
              />
            </el-tooltip>
          </div>
          <!-- Childs orders -->
          <div v-if="order_ask.deploy" class="orders-ask-body-multiple-orders">
            <div
              v-for="(order_ask_child, c) in order_ask.children"
              :key="c"
              :class="{
                pending:
                  order_ask_child.is_pretrade ||
                  !order_ask_child.preferences_matched,
              }"
              class="flex-subgroup"
              @click.stop="selectOrder(1, order_ask_child)"
            >
              <p class="orders-ask-body-ask red">
                {{ order_ask_child.price / 100 }} €
              </p>
              <p class="orders-ask-body-quantity red">
                {{ order_ask_child.quantity }}
              </p>
              <p class="orders-ask-body-account red action-light">
                <img
                  v-if="
                    labelUserType(order_ask_child) ==
                    $t('product_page.orders.pro')
                  "
                  alt="Pro account"
                  src="@/assets/svg/v2/pro.svg"
                  class="themed-img"
                />
                <img
                  v-else
                  alt="Individual account"
                  src="@/assets/svg/v2/personnal.svg"
                  class="themed-img"
                />
                <span class="hidden-tel">{{
                  labelUserType(order_ask_child)
                }}</span>
              </p>
              <p class="orders-ask-body-orders red">
                {{ order_ask_child.size }}
              </p>
              <p
                v-if="order_ask_child.is_pretrade"
                class="orders-ask-body-pending"
              >
                <el-icon class="orders-ask-body-buy-pending-icon">
                  <Loading />
                </el-icon>
              </p>
              <p class="orders-ask-body-multiple"></p>
            </div>
          </div>
        </div>

        <div v-if="orders(1).length === 0" class="empty-orders">
          {{ $t('product_page.orders.empty_sell') }}
        </div>
      </div>
    </div>
    <div
      v-if="
        (orderType === 2 && orders(2).length !== 0) ||
        (orderType === 3 && orders(3).length !== 0)
      "
      class="orders-array v3-disabled"
    >
      <div class="orders-taker">
        <div class="orders-taker-title">
          <h5 class="orders-taker-title-multiple"></h5>
          <h5 class="orders-taker-title-orders">
            {{ $t('commons.orders').toUpperCase() }}
          </h5>
          <h5 class="orders-taker-title-account">
            {{ $t('commons.account_type').toUpperCase() }}
          </h5>
          <h5 class="orders-taker-title-quantity">
            {{ $t('commons.quantity').toUpperCase() }}
          </h5>
          <h5 class="orders-taker-title-taker">
            {{ $t('commons.taker').toUpperCase() }}
          </h5>
        </div>
        <div
          v-for="(order_taker, n) in orders(2)"
          :key="n"
          class="orders-taker-body"
          @click="selectOrder(2, order_taker)"
        >
          <div
            :class="{
              pending:
                (order_taker.is_pretrade &&
                  order_taker.children.length === 0) ||
                !order_taker.preferences_matched,
            }"
            class="orders-taker-body-unique"
          >
            <p v-if="order_taker.is_pretrade" class="orders-ask-body-pending">
              <el-icon class="orders-ask-body-sell-pending-icon">
                <Loading />
              </el-icon>
            </p>
            <p
              class="orders-taker-body-multiple"
              @click.stop="order_taker.deploy = !order_taker.deploy"
            >
              <el-icon
                v-if="
                  orders(0).length > 0 &&
                  order_taker.size > 1 &&
                  !order_taker.deploy
                "
              >
                <ArrowRight color="#FF000" />
              </el-icon>
              <el-icon v-if="order_taker.deploy">
                <ArrowDown color="#FF000" />
              </el-icon>
            </p>
            <p class="orders-taker-body-orders green">{{ order_taker.size }}</p>
            <p class="orders-taker-body-account green">
              {{ labelUserType(order_taker) }}
            </p>
            <p
              v-if="order_taker.children.length === 1"
              class="orders-taker-body-quantity green"
            >
              {{}}
            </p>
            <p v-else class="orders-taker-body-quantity green">
              {{ returnGlobalQuantity(order_taker) }}
            </p>
            <p class="orders-taker-body-taker green">
              {{ order_taker.price / 100 }}
            </p>
          </div>
          <!-- Childs orders -->
          <div
            v-if="order_taker.deploy"
            class="orders-taker-body-multiple-orders"
          >
            <div
              v-for="(order_taker_child, c) in order_bid.children"
              :key="c"
              @click.stop="selectOrder(1, order_taker_child)"
              class="flex-subgroup"
            >
              <p class="orders-taker-body-multiple"></p>
              <p class="orders-taker-body-orders green">
                {{ order_taker_child.size }}
              </p>
              <p class="orders-taker-body-account green">
                {{ labelUserType(order_taker_child) }}
              </p>
              <p class="orders-taker-body-quantity green">
                {{ order_taker_child.quantity }}
              </p>
              <p class="orders-taker-body-taker green">
                {{ order_taker_child.price / 100 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        (orderType === 3 && orders(3).length !== 0) ||
        (orderType === 3 && orders(3).length !== 0)
      "
      class="orders-array"
    >
      <div class="orders-taker">
        <div class="orders-taker-title">
          <h5 class="orders-taker-title-multiple"></h5>
          <h5 class="orders-taker-title-orders">
            {{ $t('commons.orders').toUpperCase() }}
          </h5>
          <h5 class="orders-taker-title-account">
            {{ $t('commons.account_type').toUpperCase() }}
          </h5>
          <h5 class="orders-taker-title-quantity">
            {{ $t('commons.quantity').toUpperCase() }}
          </h5>
          <h5 class="orders-taker-title-taker">
            {{ $t('commons.taker').toUpperCase() }}
          </h5>
        </div>
        <div
          v-for="(order_taker, n) in orders(2)"
          :key="n"
          class="orders-taker-body"
          @click="selectOrder(2, order_taker)"
        >
          <div
            :class="{
              pending:
                order_taker.is_pretrade && order_taker.children.length === 0,
            }"
            class="orders-taker-body-unique"
          >
            <p
              class="orders-taker-body-multiple"
              @click.stop="order_taker.deploy = !order_taker.deploy"
            >
              <el-icon
                v-if="
                  orders(0).length > 0 &&
                  order_taker.size > 1 &&
                  !order_taker.deploy
                "
              >
                <ArrowRight color="#FF000" />
              </el-icon>
              <el-icon v-if="order_taker.deploy">
                <ArrowDown color="#FF000" />
              </el-icon>
            </p>
            <p v-if="order_taker.is_pretrade" class="orders-ask-body-pending">
              <el-icon class="orders-ask-body-pending-icon">
                <Loading />
              </el-icon>
            </p>
            <p class="orders-taker-body-orders green">{{ order_taker.size }}</p>
            <p class="orders-taker-body-account green">
              {{ labelUserType(order_taker) }}
            </p>
            <p
              v-if="order_taker.children.length === 1"
              class="orders-taker-body-quantity green"
            >
              {{}}
            </p>
            <p v-else class="orders-taker-body-quantity green">
              {{ returnGlobalQuantity(order_taker) }}
            </p>
            <p class="orders-taker-body-taker green">
              {{ order_taker.price / 100 }}
            </p>
          </div>
          <!-- Childs orders -->
          <div
            v-if="order_taker.deploy"
            class="orders-taker-body-multiple-orders"
          >
            <div
              v-for="(order_taker_child, c) in order_bid.children"
              :key="c"
              @click.stop="selectOrder(1, order_taker_child)"
              class="flex-subgroup"
            >
              <p class="orders-taker-body-multiple"></p>
              <p class="orders-taker-body-orders green">
                {{ order_taker_child.size }}
              </p>
              <p class="orders-taker-body-account green">
                {{ labelUserType(order_taker_child) }}
              </p>
              <p class="orders-taker-body-quantity green">
                {{ order_taker_child.quantity }}
              </p>
              <p class="orders-taker-body-taker green">
                {{ order_taker_child.price / 100 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!getAuthStatus && orderType === 1" class="result-no-auth">
      <div class="result-no-auth-content">
        <div class="result-no-auth-img">
          <img alt="Lock search" src="@/assets/svg/v2/subscriptions/lock.svg" class="themed-img"/>
        </div>
        <div class="result-no-auth-title">
          <h3>{{ $t('search.no_auth_title') }}</h3>
          <button @click="$router.push('/register')">
            <p>{{ $t('search.no_auth_register') }}</p>
            <img alt="Register" src="@/assets/svg/v2/arrowToRight.svg" class="themed-img"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ArrowRight,
  ArrowLeft,
  ArrowDown,
  Loading,
} from '@element-plus/icons-vue'
</script>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ElMessage } from 'element-plus'

export default {
  props: {
    orderType: {
      type: Number,
      required: true,
    },
  },
  emits: ['checkCreateOrderAgree'],
  data() {
    return {
      swap_submenu: 0,

      orderAlert: {
        price: null,
        isbn: null,
        type: null,
      },
    }
  },
  mounted() {
    this.getOrderAlertLinkToProduct(this.$route.params.isbn)
  },
  computed: {
    ...mapGetters({
      getProduct: 'getProduct',
      getOrders: 'getOrders',
      getOrder: 'getOrder',
      getAuthStatus: 'getIsAuth',
      getLocationSelected: 'getLocationSelected',
      getCurrentOrdersAlerts: 'getCurrentOrdersAlerts',
    }),
  },
  methods: {
    ...mapActions({
      getOrderActions: 'getOrder',
      createOrderAlertAction: 'createOrderAlertAction',
      getOrderAlertLinkToProduct: 'getOrderAlertLinkToProduct',
    }),
    orders(type) {
      const orders_filter = this.getOrders.filter(
        (order) => order.order_type === type
      )

      if (type === 0 || type === 3) {
        orders_filter.sort(function compare(a, b) {
          if (a.price > b.price) return -1
          if (a.price < b.price) return 1
          return 0
        })
      } else {
        orders_filter.sort(function compare(a, b) {
          if (a.price < b.price) return -1
          if (a.price > b.price) return 1
          return 0
        })
      }

      return orders_filter
    },
    async selectOrder(numbers, order) {
      if (this.getLocationSelected) {
        order.shipment = 1
      }

      if (!order.is_pretrade) {
        order.numbers = numbers
        if (order.isbn) {
          if (order.preferences_matched) {
            await this.getOrderActions(order)
              .then(() => {
                this.$router.push('/product/order/informations')
              })
              .catch((error) => {
                if (error.response.status === 406) {
                  this.$router.push('/register')
                }
              })
          } else {
            ElMessage({
              message: this.$t('product_page.orders.not_match_preferences'),
              type: 'error',
              grouping: true,
            })
          }
        }
      }
    },
    selectSwapSubmenu(submenu) {
      this.swap_submenu = submenu
    },
    labelUserType(order) {
      let individualExist = false
      let proExist = false

      if (order.hasChildren && order.children.length > 0) {
        for (let children = 0; children < order?.children.length; children++) {
          if (order?.children[children]?.user?.type === 0) {
            individualExist = true
          } else if (order?.children[children]?.user?.type === 1) {
            proExist = true
          }
        }

        if (individualExist && !proExist) {
          return this.$t('product_page.orders.individual')
        } else if (!individualExist && proExist) {
          return this.$t('product_page.orders.pro')
        } else {
          return this.$t('product_page.orders.mix')
        }
      } else {
        if (order.user.type === 0) {
          return this.$t('product_page.orders.individual')
        } else if (order.user.type === 1) {
          return this.$t('product_page.orders.pro')
        } else {
          return this.$t('product_page.orders.mix')
        }
      }
    },
    returnGlobalQuantity(order) {
      let count = 0

      if (order.hasChildren && order.children.length > 0) {
        for (let children = 0; children < order?.children.length; children++) {
          count += order.children[children].quantity
        }

        return count
      } else {
        return '1'
      }
    },
    async createOrderAlert(type) {
      this.orderAlert.type = type
      this.orderAlert.isbn = this.$route.params.isbn

      if (this.orderAlert.price === null) {
        this.orderAlert.price = 0
      }

      if (
        (this.orderAlert.price < 50 && this.orderAlert.price !== 0) ||
        (this.orderAlert.price > 30000 && this.orderAlert.price !== 0)
      ) {
        ElMessage({
          message: this.$t('product_page.orders.order_alert_created_price'),
          type: 'warning',
          grouping: true,
        })
      } else {
        await this.createOrderAlertAction(this.orderAlert)
          .then(async () => {
            ElMessage({
              message: this.$t('product_page.orders.order_alert_created'),
              type: 'success',
              grouping: true,
            })

            await this.getOrderAlertLinkToProduct(this.orderAlert.isbn)
            this.orderAlert.price = null
          })
          .catch(() => {
            ElMessage({
              message: this.$t('product_page.orders.order_alert_errored'),
              type: 'error',
              grouping: true,
            })
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.flex-subgroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-left: 60px;
}

.blue {
  color: $primary-color !important;
}

.orders,
.orders-swap,
.orders-buy-sell,
.swap-title,
.buy-sell-title,
.orders-bid-body-account {
  display: flex;
  padding-right: 15px;
}

.orders {
  justify-content: center;
  flex-direction: column;
  width: 1300px;
  margin: auto;

  .orders-buy-sell,
  .orders-swap {
    justify-content: space-between;
  }

  .buy-sell-title {
    padding: 10px 0;
    background-color: var(--background-color-14);

    h3 {
      width: 50%;
      text-align: center;
      font-size: 21px;
    }
  }

  .swap-title {
    background-color: var(--background-color-14);

    h3 {
      padding: 10px 0;
      min-width: 150px;
      width: 100%;
      text-align: center;
      font-size: 21px;
      cursor: pointer;
    }

    .submenu {
      width: 200%;
      background-color: $primary-color;
      color: var(--text-color);
    }
  }
}

.orders-array {
  display: flex;
  width: 100%;
  margin: auto;
  border-radius: 12px;
  border: 1px solid var(--border);
  background: var(--background-color-2);

  .orders-bid {
    border-right: 1px solid var(--border);
  }

  .orders-ask {
    border-left: 1px solid var(--border);
  }

  .orders-bid,
  .orders-ask,
  .orders-taker {
    display: flex;
    flex-direction: column;
    width: 100%;

    .orders-bid-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px 0px 0px 12px;
      height: 70px;
      background: $bg-gradient-buy;

      .orders-bid-header-left {
        display: flex;
        align-items: center;
        padding: 21px;
        color: var(--text-color);
        font-weight: 500;

        .bid-green {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $buy-color;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 13px;
        }

        p {
          font-size: 18px;
        }
      }

      .orders-options {
        display: flex;
        margin-right: 12px;

        .orders-bid-header-right {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: 40px;
          border-radius: 6px;
          font-weight: 500;
          background: $buy-var-color;
          color: var(--text-color);
          font-size: 16px;
          cursor: pointer;
          margin-right: 12px;
          padding-left: 8px;
          padding-right: 8px;

          img {
            margin-right: 5px;
          }
        }
      }
    }

    .orders-ask-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0px 12px 12px 0px;
      background: $bg-gradient-sell;
      height: 70px;

      .orders-ask-header-left {
        display: flex;
        align-items: center;
        padding: 21px;
        color: var(--text-color);

        .ask-red {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $sell-color;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 13px;
        }

        p {
          font-size: 18px;
        }
      }

      .orders-options {
        display: flex;
        margin-right: 12px;

        .orders-ask-header-right {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: 40px;
          font-weight: 500;
          border-radius: 6px;
          background: $sell-var-color;
          color: var(--text-color);
          font-size: 16px;
          cursor: pointer;
          margin-right: 12px;
          padding-left: 8px;
          padding-right: 8px;

          img {
            margin-right: 5px;
          }
        }
      }
    }

    .orders-ask-title {
      display: flex;
      margin-bottom: 10px;
      padding-bottom: 15px;
      border-bottom: 2px solid $sell-var-color;
      justify-content: space-between !important;

      h5 {
        font-size: 16px;
        font-weight: 500;
      }

      .orders-ask-title-ask {
        text-align: left;
        margin-left: 26px;
      }
    }

    .orders-bid-title,
    .orders-taker-title {
      display: flex;
      margin-bottom: 10px;
      padding-bottom: 15px;
      border-bottom: 2px solid $buy-var-color;

      h5 {
        font-size: 16px;
        font-weight: 500;
        color: var(--subtitle-text-color);
      }

      .orders-bid-title-multiple,
      .orders-ask-title-multiple,
      .orders-taker-title-multiple {
        width: 10%;
      }

      .orders-bid-title-orders,
      .orders-ask-title-orders,
      .orders-taker-title-orders {
        width: 20%;
        text-align: center;
      }

      .orders-bid-title-account,
      .orders-ask-title-account,
      .orders-taker-title-account {
        width: 40%;
        text-align: center;
      }

      .orders-bid-title-quantity,
      .orders-ask-title-quantity,
      .orders-taker-title-quantity {
        width: 15%;
        text-align: center;
      }

      .orders-bid-title-bid,
      .orders-ask-title-ask,
      .orders-taker-title-taker {
        width: 15%;
        text-align: right;
      }

      .orders-ask-title-ask {
        text-align: left;
        margin-left: 26px;
      }
    }

    .orders-bid-title {
      padding-right: 26px;

      h5 {
        color: var(--text-color);
      }
    }

    .orders-bid-body,
    .orders-ask-body,
    .orders-taker-body {
      padding: 5px;
      cursor: pointer;
      overflow: hidden;

      .orders-bid-body-unique,
      .orders-ask-body-unique,
      .orders-taker-body-unique {
        display: flex;
        align-items: center;
      }

      .orders-bid-body-unique {
        justify-content: end;

        .orders-bid-body-account {
          width: 100px;
          margin-left: 133px;
          border: none;;

          img {
            margin: 0 3px;
          }
        }

        .orders-bid-body-quantity {
          width: 100px;
          margin-left: 70px;
        }

        .orders-bid-body-bid {
          display: flex;
          justify-content: center;
          width: 107px;
        }
      }

      .line-blocktrade {
        margin-left: 50px;
        justify-content: space-between !important;
      }

      .orders-ask-body-unique {
        justify-content: start;
      }

      .orders-ask-body-account {
        display: flex;
        align-items: center;
        width: 100px;
        padding-right: 15px;
        margin-right: 133px;
        gap: 5px;
        color: var(--text-color);
        background: $primary-color;
        border: none;;
      }

      .orders-ask-body-quantity {
        width: 100px;
        margin-right: 70px;
      }

      .orders-ask-body-ask {
        display: flex;
        justify-content: center;
        width: 107px;
        margin-right: 25px;
      }

      .orders-bid-body-multiple-orders,
      .orders-ask-body-multiple-orders,
      .orders-taker-body-multiple-orders {
        padding: 5px 0;
        font-size: 14px;

        div {
          display: flex;
          padding: 3px 0;

          &:hover {
            background-color: var(--background-color-17);
          }
        }
      }

      &:hover {
        background-color: var(--background-color-14);
      }
    }
  }
}

.pending {
  background-color: var(--background-color-11);
  opacity: 1;
  cursor: not-allowed;

  p {
    color: var(--subtitle-3-text-color);
  }
}

.orders-ask-body-multiple {
  margin-left: 15px;
  text-align: right;
  color: var(--text-color) !important;
}

.orders-ask-body-pending {
  position: relative;
  margin-bottom: 18px;
  width: 10%;

  .orders-ask-body-sell-pending-icon {
    position: absolute;
    padding-left: 20px;
  }
  .orders-ask-body-buy-pending-icon {
    position: absolute;
    padding-right: 20px;
    margin-bottom: 15px;
  }
}

.orders-bid-body-orders,
.orders-ask-body-orders,
.orders-taker-body-orders {
  text-align: center;
  color: var(--text-color);
  padding-left: 5px;
}

.orders-bid-body-account,
.orders-ask-body-account,
.orders-taker-body-account {
  justify-content: center;
  align-items: center;
  width: 103px;
  text-align: center;
  color: var(--text-color);
  border-radius: 6px;
  border: 1px solid var(--border);
  font-size: 14px;
  gap: 5px;
  background: $primary-color;
}

.orders-bid-body-quantity,
.orders-ask-body-quantity,
.orders-taker-body-quantity {
  text-align: center;
  color: var(--text-color);
}

.orders-bid-body-bid,
.orders-ask-body-ask,
.orders-taker-body-taker {
  text-align: right;
  color: var(--buysuccess-color);
}

.orders-ask-body-ask {
  text-align: left;
  color: $sell-color;
}

.empty-orders {
  text-align: center;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  color: var(--subtitle-2-text-color);
  font-size: 16px;
  font-weight: 500;
  max-width: 218px;
}

.result-no-auth {
  position: absolute;
  z-index: 5;
  left: 50%;
  bottom: -210px;
  transform: translate(-50%, 0);
  width: 1298px;
  margin: auto;
  padding: 30px 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(3px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  .result-no-auth-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .result-no-auth-img {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: var(--background-color-7);
      height: 42px;
      width: 42px;
      margin-bottom: 7px;
    }

    .result-no-auth-title {
      h3 {
        color: var(--text-color);
        font-size: 16px;
        font-weight: 500;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: 0;
        margin: auto;
        margin-top: 7px;

        p {
          color: var(--light-blue);
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
}

.block-trade-title {
  background: $primary-color !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 13px;
}

@media screen and (max-width: 500px) {
  .hidden-tel {
    display: none;
  }

  .orders {
    width: 100%;
    margin: 0;
    border: 0;
    padding-right: 0;

    .orders-array {
      flex-wrap: wrap;
      width: 100%;
      border-radius: 0;
      border: 0;

      .orders-bid {
        border: 0;
      }
    }
  }

  .orders-ask-title {
    flex-direction: row-reverse;
    display: none !important;
  }

  .orders-bid-body-orders {
    width: 30%;
  }

  .orders-bid-body-account {
    width: 40px !important;
    padding-right: 0;
    margin: auto;
    transform: translateX(18px);
  }

  .orders-bid-body-quantity {
    text-align: right;
  }

  .orders-bid-body-bid {
    text-align: right;
    transform: translateX(15px);
  }

  .orders-ask-body-unique {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
    width: 100%;
  }

  .orders-bid-body-multiple-orders {
    .flex-subgroup {
      flex-direction: row;
    }
  }
  .orders-ask-body-multiple {
    margin-left: 0;
    flex-direction: row-reverse;
  }

  .flex-subgroup {
    margin-left: 30px;
    flex-direction: row-reverse;
    .orders-bid-body-account {
      margin: auto;
    }
    .orders-bid-body-quantity {
      width: 40px;
      text-align: right;
      transform: translateX(18px);
    }
    .orders-bid-body-bid {
      width: 107px !important;
      justify-content: center !important;
      text-align: center !important;
      transform: translateX(30px);
    }
  }

  .orders-bid-header-left,
  .orders-ask-header-left {
    padding-left: 5px !important;
    padding-right: 5px !important;

    .bid-green,
    .ask-red {
      margin-right: 5px !important;
    }

    p {
      display: none;
    }
  }

  .orders-bid-header-right,
  .orders-ask-header-right {
    margin-right: 9px !important;
    text-align: center;

    img {
      display: none;
    }
  }

  .orders-options {
    margin-right: 0 !important;
  }

  .orders-ask-body-multiple svg {
    transform: rotateX(360deg) rotateY(180deg);
  }

  .orders-ask-body-orders {
    width: 30%;
    transform: translateX(-2px);
  }

  .orders-ask-body-account {
    width: 40px !important;
    padding-right: 0;
    margin: auto;
    transform: translateX(18px);
  }

  .orders-ask-body-ask {
    width: 17%;
    margin-right: 0 !important;
    transform: translateX(15px);
  }

  .orders-ask-body-multiple-quantity {
    margin-left: 50px !important;
  }

  .flex-subgroup {
    .orders-ask-body-quantity {
      transform: translateX(12px);
    }
    .orders-ask-body-ask {
      transform: translateX(25px);
    }
  }

  .orders-ask-body-quantity {
    margin-right: 10px !important;
    width: 40px !important;
    text-align: right;
  }

  .orders-ask-body-account {
    width: 40px !important;
    padding-right: 0 !important;
    margin: auto !important;
  }

  .orders-bid-body-unique {
    justify-content: start !important;
  }

  .orders-bid-body-account,
  .orders-ask-body-account,
  .orders-bid-body-quantity,
  .orders-ask-body-quantity {
    word-break: keep-all;
    margin-left: 10px !important;
  }

  .orders-ask {
    margin-top: 10px;
    border-left: 0 !important;
  }

  .orders-bid-header,
  .orders-ask-header {
    border-radius: 0 !important;
  }
}
</style>
