<template>
  <div class="compare-bottle-dialog" id="dialog-compare-box">
    <div class="compare-bottle-dialog-header">
      <h3>{{ $t('product_page.price_history.compare_dialog.title') }}</h3>

      <search_V2
        :pageKey="'searchProduct'"
        @search="searchProducts"
        :compare="true"
      ></search_V2>
    </div>

    <div class="compare-bottle-dialog-body">
      <div
        v-if="getProducts['searchProduct']?.length > 0"
        class="search-results"
      >
        <div
          v-for="(product, n) in getProducts['searchProduct']"
          :key="n"
          @click="addPriceHistory(product)"
          class="search-result"
        >
          <div class="bloc-divider">
            <div class="bloc-left">
              <div class="search-picture">
                <img
                  v-if="returnUrl(product.files_attachments)"
                  :src="returnUrl(product.files_attachments)"
                  :alt="product.title"
                  width="50"
                  height="50"
                />
                <img
                  v-else
                  src="@/assets/svg/v2/bottle.svg"
                  alt="Bottle"
                  width="50"
                  height="50"
                />
              </div>

              <div class="title-ticker">
                <h4>{{ product.title }}</h4>
                <p>{{ product.ticker_maitre }}</p>
              </div>
            </div>

            <button>{{ $t('commons.select') }}</button>
          </div>

          <el-divider
            v-if="n < getProducts['searchProduct']?.length - 1"
            class="divider"
          />
        </div>
      </div>

      <div v-else class="search-empty">
        <p>{{ $t('product_page.price_history.compare_dialog.no_select') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import search_V2 from '../search_V2.vue'

export default {
  emits: ['addBottle'],
  components: {
    search_V2,
  },
  data() {
    return {
      search: '',
      last_pending_search: '',
      payload: {
        type: 'searchProduct',
        search: '',
        spirit_type: 14,
      },
    }
  },
  mounted() {
    this.$store.commit('RESET_SEARCH', { key: 'searchProduct', products: [] })
  },
  computed: {
    ...mapGetters({
      getProducts: 'getProducts',
      getAuthStatus: 'getIsAuth',
      getProductsTotal: 'getProductsTotal',
      getSearchPending: 'getSearchPending',
      getLists: 'getLists',
    }),
  },
  methods: {
    ...mapActions({
      autoSearchAuth: 'autocompleteSearchAuth',
    }),
    returnUrl(pictures) {
      if (pictures) {
        return pictures[0]
      }
    },
    searchProducts(searchProducts, key) {
      this.search = searchProducts

      this.payload.search = searchProducts.value
        ? searchProducts.value
        : searchProducts.search
      this.payload.spirit_type = searchProducts.spirit_type
      if (!this.payload.filters) {
        this.payload.filters = {
          spirit_type: null,
          country: null,
          age: null,
          vintage: null,
          single_cask: false
        }
      }

      if (
        searchProducts.value?.length > 0 ||
        searchProducts.search?.length > 0
      ) {
        if (!this.getSearchPending['searchProduct']) {
          const formattedPayload = {
            type: key,
            search: searchProducts.value
              ? searchProducts.value
              : searchProducts.search,
            spirit_type: searchProducts.spirit_type,
          }
          if (!formattedPayload.filters) {
            formattedPayload.filters = {
              spirit_type: null,
              country: null,
              age: null,
              vintage: null,
              single_cask: false
            }
          }
          this.last_pending_search = searchProducts

          this.autoSearchAuth(formattedPayload)
        }
      } else {
        this.$store.commit('RESET_SEARCH', { key: key, products: [] })
      }
    },
    addPriceHistory(product) {
      this.$emit('addBottle', product)
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.compare-bottle-dialog {
  .compare-bottle-dialog-header {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--border);

    h3 {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 600;
    }
  }

  .compare-bottle-dialog-body {
    background: var(--background-color-2);
    padding: 9px;
    padding-bottom: 1px;

    .search-results {
      max-height: 300px;
      overflow-y: scroll;

      .search-result {
        .bloc-divider {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 11px;
          border-radius: 12px;
          margin-bottom: 8px;

          .bloc-left {
            display: flex;

            .search-picture {
              margin-right: 15px;

              img {
                border-radius: 6px;
              }
            }
          }

          .title-ticker, 
          .title-ticker p {
            color: var(--text-color);

          }

          button {
            background: transparent;
            border: 0;
            color: var(--text-color);
            font-size: 15px;
            font-weight: 600;
          }
        }

        .divider {
          margin: 8px auto;
          border-color: var(--border);
        }
      }

      .bloc-divider:hover {
        background: var(--bg-hover-color);
      }
    }

    .search-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 96px;

      p {
        color: var(--subtitle-2-text-color);
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .compare-bottle-dialog {
    min-width: auto;
    width: 350px;
  }

  .bloc-divider {
    button {
      display: none;
    }
  }

  .history-tag-bottle {
    margin-left: 5px;
    margin-right: 5px !important;

    h4 {
      font-size: 13px !important;
    }
  }

  .product-history-compare-bloc {
    flex-wrap: wrap;
  }

  .hc {
    margin-bottom: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
  }

  .product-history-infos-bloc {
    display: flex;
    flex-wrap: wrap;

    .product-history-chart-options-bloc {
      flex-direction: column;

      .high-low-perf,
      .chart-options {
        border: 0;
        border-radius: 0;
        width: 100% !important;
      }

      .high-low-perf {
        max-width: initial !important;
      }

      .chart-options {
        padding: 20px 0;

        h4 {
          margin-left: 10px;
        }

        div {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }

  .price-bloc-data {
    .product-history-compare-bloc {
      margin-left: 10px;

      .product-history-compare {
        margin-bottom: 10px;
      }
    }
  }

  .product-history-chart-composite-data {
    width: auto !important;
  }

  .hc-line-composite
    .product-history-infos-bloc
    .product-history-chart-options-bloc
    .high-low-perf {
    width: 100%;
  }

  .history-line {
    flex-wrap: wrap-reverse;

    .composite-choice {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    img {
      display: none;
    }

    p {
      margin-left: 5px;
    }

    .explore {
      margin-left: 5px !important;
    }
  }

  .product-history-chart-composite-data {
    width: auto;
    border: 0;
    border-radius: 0;
  }

  .select-currency {
    width: 105px !important;
    margin-right: 0 !important;

    .select-trigger > .el-input {
      margin-right: 0 !important;

      .el-input__wrapper {
        margin-right: 0 !important;
      }
    }
  }

  .high-low-perf {
    max-width: auto !important;
  }
}

.search-results::-webkit-scrollbar {
  width: 5px;
}

.search-results::-webkit-scrollbar-track {
  background: var(--background-color);
}

.search-results::-webkit-scrollbar-thumb {
  background-color: var(--bg-constrast);
  border-radius: 10px;
}

.search-results::-webkit-scrollbar-thumb:hover {
  background-color: var(--background-color-6);
}
</style>
