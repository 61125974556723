<template>
  <div class="report-dialog-content">
    <form>
      <div class="form-input-bloc">
        <h3>{{ $t('product_page.report.error_type') }}</h3>
        <el-select
          :placeholder="$t('product_page.report.select')"
          class="generic-border"
          filterable
          v-model="reportForm.error.errors"
          multiple
          :effect="darkmode ? 'dark' : ''"
        >
          <el-option
            v-for="item in getLists.product_errors"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>

      <div class="form-input-bloc">
        <h3>{{ $t('product_page.report.description') }}</h3>
        <el-input
          v-model="reportForm.error.description"
          :autosize="{ minRows: 2, maxRows: 2 }"
          type="textarea"
          :placeholder="$t('product_page.report.description_placeholder')"
        />
      </div>

      <div class="form-input-bloc">
        <h3>
          URL <span>({{ $t('product_page.report.optional') }})</span>
        </h3>
        <el-input
          v-model="reportForm.error.url"
          :placeholder="$t('product_page.report.url_placeholder')"
          class="generic-border"
        />
      </div>

      <div class="form-input-bloc">
        <h3>
          {{ $t('product_page.report.pictures') }}
          <span>({{ $t('product_page.report.optional') }})</span>
        </h3>
        <input
          @change="handleFileChange"
          :multiple="true"
          ref="file_1"
          name="document"
          class="custom-file-input"
          type="file"
          accept="image/jpg, image/jpeg, image/png"
        />

        <div v-if="filePreviews.length > 0">
          <div class="image-previews">
            <div
              v-for="(file, index) in filePreviews"
              :key="index"
              class="image-preview"
              @mouseenter="showDeleteButton(index)"
              @mouseleave="hideDeleteButton(index)"
            >
              <img
                :src="file.preview"
                alt="Selected Image"
                class="image-preview"
              />

              <button class="delete-button" @click="removeFile(index)">
                <img
                  id="delete"
                  :src="require(`@/assets/svg/v2/delete.svg`)"
                  class="themed-img"
                  alt="Delete"
                />
              </button>
            </div>
          </div>
        </div>

        <div class="max-pictures">
          <img src="@/assets/svg/v2/checked.svg" alt="Max pictures : 2" class="themed-img"/>
          <p>{{ $t('product_page.report.pictures_max') }}</p>
        </div>
      </div>

      <button @click.prevent="sendReport" class="generic-btn send">
        {{ $t('product_page.report.send') }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ElMessage } from 'element-plus'
import { inject } from "vue"

export default {
  emits: ['close'],
  data() {
    return {
      darkmode: inject("darkmode"),
      filesNameList: [],
      filePreviews: [],
      reportForm: {
        type: 0,
        error: {
          isbn: null,
          errors: [],
          description: null,
          url: null,
          files: [],
        },
      },
      ext: /[A-Za-z0-9_\-()ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ]+.(jpg|jpeg|png|JPG|PNG|JPEG)$/,
    }
  },
  computed: {
    ...mapGetters({
      getProduct: 'getProduct',
      getLists: 'getLists',
    }),
  },
  methods: {
    ...mapActions({
      errorMissingBottle: 'errorMissingBottle',
    }),
    handleFileChange() {
      if (this.$refs.file_1.files.length > 2) {
        ElMessage({
          message: this.$t('product_page.report.too_many_files'),
          type: 'error',
          grouping: true,
          duration: 5000,
        });
        this.$refs.file_1.value = null;
        this.filePreviews = [];
        return;
      }
      
      this.updateFilePreviews();
    },
    updateFilePreviews() {
      this.filePreviews = Array.from(this.$refs.file_1.files).map(file => {
        const preview = URL.createObjectURL(file);
        return { file, preview, showDeleteButton: false };
      });
    },
    showDeleteButton(index) {
      this.$set(this.filePreviews[index], 'showDeleteButton', true);
    },
    hideDeleteButton(index) {
      this.$set(this.filePreviews[index], 'showDeleteButton', false);
    },
    removeFile(index) {
      const dt = new DataTransfer();
      Array.from(this.$refs.file_1.files).forEach((file, i) => {
        if (i !== index) {
          dt.items.add(file);
        }
      });
      this.$refs.file_1.files = dt.files;
      this.updateFilePreviews();
    },
    async sendReport() {
      if (this.filePreviews.length > 0) {
        this.reportForm.error.files = this.filePreviews.map(filePreview => filePreview.file);
      }

      this.reportForm.error.isbn = this.getProduct.isbn;
      this.reportForm.message = this.$t('commons.error_submit');

      await this.errorMissingBottle(this.reportForm).then(() => {
        this.$emit('close', true);
      });
    },
  },
  watch: {
    '$refs.file_1.files': {
      handler: 'updateFilePreviews',
      deep: true
    }
  },
  mounted() {
    this.updateFilePreviews();
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.file-dropped {
  display: flex;
}

.image-previews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.centered {
  text-align: center;
}

.image-preview {
  min-height: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 100px;
  text-align: center;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  .delete-button {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    background: transparent;
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }

  &:hover {
    .delete-button {
      display: block;
    }
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}


.report-dialog-content {
  overflow: hidden;
  overflow-y: scroll;

  form {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;

    .form-input-bloc {
      h3 {
        color: var(--text-color);
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
        margin-top: 21px;

        span {
          color: var(--subtitle-2-text-color);
          font-size: 15px;
          font-weight: 500;
        }
      }

      .max-pictures {
        display: flex;
        margin-top: 9px;

        p {
          color: var(--subtitle-2-text-color);
          font-size: 14px;
          font-weight: 500;
          margin-left: 5px;
        }
      }
    }

    .send {
      width: 102px;
      margin: 0;
      margin: auto;
      margin-top: 34px;
      margin-bottom: 20px;
    }
  }
}

.file-dropped {
  border-radius: 8px;
  align-items: center;
  border: 1px solid var(--border);
  background: var(--background-color-2);
  padding: 15px;
  margin-bottom: 10px;
}

.custom-file-input {
  width: 100%;
  height: 41px;
  margin-top: 13px;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border-radius: 8px;
  border: 1px solid var(--border);
  background: var(--background-color-2);
  color: var(--subtitle-text-color);
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  height: 41px;
  width: 100%;
}

input[type='file']::file-selector-button:hover {
  background: var(--background-color-3);
}

.report-dialog-content::-webkit-scrollbar {
  width: 0px;
}

.report-dialog-content::-webkit-scrollbar-track {
  background: var(--background-color-2);
}

.report-dialog-content::-webkit-scrollbar-thumb {
  background-color: var(--background-color-9);
  border-radius: 10px;
}

.report-dialog-content::-webkit-scrollbar-thumb:hover {
  background-color: $primary-color;
}

.form-input-bloc {
  textarea {
    color: var(--text-color);
  }
}

.el-select .el-tag {
  background-color: var(--background-color-3) !important;
  color: var(--text-color) !important;
}

.el-select-dropdown .el-select-dropdown__item.selected {
  background-color: var(--background-color-3) !important;
}

@media screen and (max-height: 850px) {
  .report-dialog {
    .report-dialog-content {
      top: 410px;
    }
  }
}

@media screen and (max-width: 500px) {
  input[type='file']::file-selector-button,
  .custom-file-input {
    width: 100%;
  }

  form {

    .form-input-bloc {
      h3 {
        margin-top: 15px !important;
      }
    }
  }

  .send {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
</style>
