<template>
  <div class="history-line-composite">
    <h2>{{ $t('product_page.price_history.price_history') }}</h2>
    
    <div class="history-delay">
      <div v-if="getPriceHistoryInfos?.delay !== 0" class="history-subscription-empty">
        <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
        <p>{{ $t('product_page.price_history.delay') }} {{ getPriceHistoryInfos?.delay }} {{ $t('product_page.price_history.delay_2') }}</p>
        <div class="explore" @click="$router.push('/subscriptions')">
          <h3>{{ $t('settings.subscriptions.explore') }}</h3>
        </div>
      </div>

      <div class="composite-choice">
        <p>{{ $t('product_page.price_history.composite.display_composite') }}</p>
        <el-switch
            v-model="toggle"
            :disabled="!getProduct.ticker_composite"
            style="--el-switch-on-color: #2667FF; --el-switch-off-color: #333333"
          />
      </div>
    </div>
  
  </div>

  <div class="composite-bottle-size-options">
    <el-select
      v-model="allowBottleSize"
      multiple
      placeholder=""
      style="max-width: 330px"
    >
      <el-option
        v-for="item in getAvailablesSizes"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
  </div>

  <div class="hc-line-composite" v-loading="chartLoading || currencyLoading" element-loading-background="rgba(34, 34, 34, 0.8)" >
    <div class="hc" v-if="chartLoading"></div>
    <highcharts v-else-if="getPriceCompositeHistory.length > 0" class="hc" :options="chartOptions" ref="chart"></highcharts>

    <div class="product-history-infos-bloc" >
      <div class="product-history-chart-options-bloc">
        <div class="product-history-chart-composite-data">
          <div class="product-history-chart-composite-data-left">
            <h4>{{ $t('product_page.number_bottles')}}</h4>
            <h5>{{ getPriceCompositeHistory.length }}</h5>
          </div>
          <el-divider class="divider-chart-options" direction="vertical" />
  
          <div class="product-history-chart-composite-data-right">
            <h4>{{ $t('product_page.ticker_composite')}}</h4>
            <div class="identification">
              <p class="identification-code">{{ getProduct.ticker_composite }}</p>
            </div>
          </div>
        </div>
        <div class="product-history-chart-choice-composite">
          <button :class="{ 'isSelected': type_of_chart === 1 }">
            <img src="@/assets/svg/v2/products/line.svg" alt="Line chart" class="themed-img"/>
            <span>
              {{ $t('product_page.price_history.line_chart') }}
            </span>
          </button>
        </div>
        <div class="high-low-perf">
          <div class="high-low-perf-header">
            {{ getProduct.ticker }}

          </div>
          <div class="high-low-perf-body">
            <div class="high">
              <div class="high-title">
                <h4>{{ $t('product_page.price_history.high') }}</h4>
              </div>
              <div class="high-price">
                <h5>{{ getMax().max }}</h5>
                <p>{{ getMax().date }}</p>
              </div>
            </div>
            <div class="low">
              <div class="low-title">
                {{ $t('product_page.price_history.low') }}
              </div>
              <div class="low-price">
                <h5>{{ getMin().min }}</h5>
                <p>{{ getMin().date }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-options">
          <h4>{{ $t('product_page.price_history.chart_options') }}</h4>
  
          <div>
            <h5>{{ $t('product_page.price_history.currency') }}</h5>
            <el-select v-model="selectCurrency" :effect="darkmode ? 'dark' : ''" class="select-currency">
              <el-option v-for="item in getLists?.currency" :key="item.currency" :label="item.currency" :value="item.currency" />
            </el-select>
          </div>
          <div>
            <h5>{{ $t('product_page.price_history.fees') }}</h5>
            <div>
              <p v-if="withFees">{{ $t('commons.enabled') }}</p>
              <p v-else>{{ $t('commons.disabled') }}</p>
              <el-checkbox v-model="withFees" text-color="#FFFFFF" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
<script>
import { mapGetters, mapActions } from 'vuex';
import { findIndex } from 'lodash';
import m from 'moment';
import { inject } from "vue"
  
export default {
  props: {
    reset: {
      type: Number,
      required: true,
      default: 0
    },
    fees: {
      type: Number,
      require: true,
      default: 1
    },
    type_of_chart: {
      type: Number,
      require: true,
      default: 0
    }
  },
  emits: ['currency', 'type_of_chart'],
  data() {
    return {
      darkmode: inject("darkmode"),
      displayCompositeTicker: true,

      line_chart_options: [],
      allowBottleSize: [],

      maxTimestamp: null,
      minTimestamp: null,

      lowCheck: null,
      highCheck: null,
      averageCheck: null,
      perf: null,
      withFees: true,
      selectCurrency: 'EUR',

      chartLoading: true,
      currencyLoading: false,

      cat: [],

      chartOptions: {
        accessibility: {
          description: 'Bottle price history'
        },
        legend: {
          enabled: false
        },
        tooltip: {
          shared: true,
          split: false,
          distance: 25,
          backgroundColor: '#333333',
          useHTML: true,
          headerFormat: '',
          pointFormat: `<span style="color:{series.color}">
            <b>{point.name}</b><br>
            Ticker: <b>{point.ticker}</b></span><br/>
            {series.name}: <b>{point.y}</b><br/>
            Date: <b>{point.date}</b></span><br/>`,
          style: {
            color: '#FFFFFF'
          },        
        },
        credits: {
          enabled: false
        },
        chart: {
          backgroundColor: '#333333',
        },
        plotOptions: {
          column: {
            grouping: false
          },
          line: {
            marker: {
              enabled: false
            },
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click: function () {
                    window.open('/product/' + this.options.point_isbn, '_blank');
                }
              }
            }
          }
        },
        series: [
        {
          type: "line",
          name: 'Price',
          isbn: 'isbn',
          data: [],
          color: "#00000020",
          cursor: 'pointer',
          accessibility: {
            description: 'Price'
          },
          showInNavigator: true,
        },
      ],
      yAxis: [
        {
          labels: {
            align: "left",
            x: -10,
          },
          height: "100%",
          lineWidth: 0,
          resize: {
            enabled: true,
            lineDashStyle: 'Solid',
            lineColor: '#ffffff'
          },
          gridLineColor: '#FFFFFF3D',
          title: {
            text:  '',
          }
        },
      ],
      xAxis: [
        {
          visible: true,
          events: {
            setExtremes: (e) => this.handleRangeChange(e)            
          },
          categories: [],
          labels: {
            y: 20,
            enabled: true,
          } 
        },
      ],
    }
    };
  },
  mounted() {
    this.resetLineChartData();
    this.selectCurrency = this.getUserDetails?.preferences?.currency || 'EUR';

    this.allowBottleSize = this.getAvailablesSizes;
  },
  watch: {
    getPriceCompositeHistory() {
      this.resetLineChartData();
    },
    reset() {
      this.resetLineChartData();
    },
    fees() {
      this.resetLineChartData();
    },
    allowBottleSize() {
      this.resetLineChartData();

      if (this.allowBottleSize.length === 0) {
        this.allowBottleSize = this.getAvailablesSizes;
      }
    },
    async selectCurrency() {
      this.currencyLoading = true;
      this.$emit('currency', this.selectCurrency);

      const payload = {
        currency: this.selectCurrency
      }
      
      await this.choiceCurrency(payload).then(() => {
        this.getClient();
      });
    },
    averageCheck() {
      if (this.averageCheck && !this.line_chart_options.includes('Average')) {
        this.line_chart_options.push('Average');
      } else {
        const indexAverage = findIndex(this.line_chart_options, (e) => {
          return e === 'Average';
        });

        this.line_chart_options.splice(indexAverage, 1);
      }
    },
    withFees() {
      this.resetLineChartData();
    }
  },
  computed: {
    ...mapGetters({
      getProduct: 'getProduct',
      getLists: 'getLists',
      getAuthStatus: 'getIsAuth',
      getPriceCompositeHistory: 'getPriceCompositeHistory',
      getPriceHistoryCompositeInfos: 'getPriceHistoryCompositeInfos',
      getPriceHistoryInfos: 'getPriceHistoryInfos',
      getUserDetails: 'getUserDetails',
      getAvailablesSizes: 'getAvailablesSizes'
    }),
    toggle: {
      get() {
        return this.displayCompositeTicker;
      },
      set() {
        if (this.displayCompositeTicker) {
          this.$emit("type_of_chart", 0)
          this.displayCompositeTicker = false
        } 
      }
    },
  },
  methods: {
    ...mapActions({
      getClient: 'getAccountDetails',
      choiceCurrency: 'choiceCurrency',
    }),
    getMin() {
      let minValue = null;
      let minDate = null;

      if (this.minTimestamp !== null) {
        for (const point in this.getPriceCompositeHistory) {
          let data = {
            date: this.getPriceCompositeHistory[point].date,
          };
          
          if (this.withFees === false) {
            data.min = this.getPriceCompositeHistory[point].price
          } else {
            data.min = this.getPriceCompositeHistory[point].price_with_fees
          }

          if (data.date >= this.minTimestamp) {
            if (minValue === null && data.min !== null) {
              minValue = data.min;
              minDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
            } else {
              if (minValue > data.min) {
                minValue = data.min;
                minDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
              }
            }
          }
        }
      } else {
        for (const point in this.getPriceCompositeHistory) {
         let data = {
            date: this.getPriceCompositeHistory[point].date,
          };
          
          if (this.withFees === false) {
            data.min = this.getPriceCompositeHistory[point].price
          } else {
            data.min = this.getPriceCompositeHistory[point].price_with_fees
          }

          if (minValue === null && data.min !== null) {
            minValue = data.min;
            minDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
          } else {
            if (minValue > data.min) {
              minValue = data.min;
              minDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
          }
        }
      }

      return { min: minValue?.toFixed(0), date: minDate };
    },
    getMax() {
      let maxValue = null;
      let maxDate = null;

      if (this.maxTimestamp !== null) {
        for (const point in this.getPriceCompositeHistory) {
          let data = {
            date: this.getPriceCompositeHistory[point].date,
          };
          
          if (this.withFees === false) {
            data.max = this.getPriceCompositeHistory[point].price
          } else {
            data.max = this.getPriceCompositeHistory[point].price_with_fees
          }

          if (data.date <= this.maxTimestamp) {
            if (maxValue === null && data.max !== null) {
              maxValue = data.max;
              maxDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
            } else {
              if (maxValue < data.max) {
                maxValue = data.max;
                maxDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
              }
            }
          }
        }
      } else {

        for (const point in this.getPriceCompositeHistory) {
          let data = {
            date: this.getPriceCompositeHistory[point].date,
          };
          
          if (this.withFees === false) {
            data.max = this.getPriceCompositeHistory[point].price
          } else {
            data.max = this.getPriceCompositeHistory[point].price_with_fees
          }

          if (maxValue === null && data.max !== null) {
            maxValue = data.max;
            maxDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
          } else {
            if (maxValue < data.max) {
              maxValue = data.max;
              maxDate = m(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
          }
        }
      }

      return { max: maxValue?.toFixed(0), date: maxDate };
    },
    handleRangeChange() {
      const minTicker = this.getPriceCompositeHistory[0].ticker_maitre
      const maxTicker = this.getPriceCompositeHistory[0].ticker_maitre
      this.minTimestamp = minTicker;
      this.maxTimestamp = maxTicker;
    },
    resetLineChartData() {
      const indexLine = findIndex(this.chartOptions.series, (e) => {
        return e.type === 'line';
      });

      if (indexLine !== -1) {
        this.chartOptions.series.splice(indexLine, 1);
      }

      if (this.getPriceCompositeHistory.length !== 0) {
        const newLineAverage = {
          type: "line",
          name: 'Average',
          data: [],
          color: '#ffffff',
          lineWidth: 2,
        };

        let newCat = [];

        if (this.withFees === false) {

          if (this.getPriceCompositeHistory.length !== 0) {
            for (const ticker in this.getPriceCompositeHistory) {
              const data = {
                name: this.getPriceCompositeHistory[ticker].title,
                y: this.getPriceCompositeHistory[ticker].price,
                ticker: this.getPriceCompositeHistory[ticker].ticker_maitre,
                isbn: this.getPriceCompositeHistory[ticker].isbn,
                picture: this.getPriceCompositeHistory[ticker].files_attachments,
                date: m(this.getPriceCompositeHistory[ticker].date, 'YYYY-MM-DD').format('DD/MM/YYYY')
              };

              const bottleSize = data.name.match(/\b\d+ml\b/g);

              if (bottleSize) {
                if (this.allowBottleSize.includes(bottleSize.toString())) {
                  newCat.push(data.ticker)
                  newLineAverage.data.push(data);
                }
              } else {
                newLineAverage.data.push(data);
              }
            }
          }

        } else if (this.withFees === true) {

          if (this.getPriceCompositeHistory.length !== 0) {
            for (const ticker in this.getPriceCompositeHistory) {
              const data = {
                name: this.getPriceCompositeHistory[ticker].title,
                y: this.getPriceCompositeHistory[ticker].price_with_fees,
                ticker: this.getPriceCompositeHistory[ticker].ticker_maitre,
                point_isbn: this.getPriceCompositeHistory[ticker].isbn,
                picture: this.getPriceCompositeHistory[ticker].files_attachments,
                date: m(this.getPriceCompositeHistory[ticker].date, 'YYYY-MM-DD').format('DD/MM/YYYY')
              };

              const bottleSize = data.name.match(/\b\d+ml\b/g);

              if (bottleSize) {
                if (this.allowBottleSize.includes(bottleSize.toString())) {
                  newCat.push(data.ticker)
                  newLineAverage.data.push(data);
                }
              } else {
                newLineAverage.data.push(data);
              }
            }
          }
        }

        this.chartOptions.xAxis[0].categories = newCat;
        this.chartOptions.series.push(newLineAverage);
        this.averageCheck = true;
        this.line_chart_options.push('Average');
        
        this.currencyLoading = false
      }

      this.chartLoading = false
    }
  }
};
</script>
  
<style lang="scss">
@import '@/assets/styles/root.scss';
  
.hc-line-composite {
    padding: 17px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .hc {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 17px;
        height: 543px;
        width: 100%;
        border-radius: 12px;
        border: 1px solid var(--border);
        background: var(--background-color-2);
    }
}

.product-history-infos-bloc {
  margin-left: 25px;
}

.hc-line-options {
    margin: 10px 0;
    margin-top: 20px;
    justify-content: space-between;

    .bloc-right {

      .statistiques {
        div {
            p {
                font-size: 13px;
            }

            .stats-identifier {
                width: 50px;
            }

            .high, .low,.perf {
                width: 40px;
            }

            .on {
                width: 25px;
            }
        }
      }
    }

    .options-bloc-hc {
        flex-direction: column;
        margin: 0 8px;
    }

    .options-bloc-type-line-hc {
        align-items: flex-start;
        margin-left: 22px;
    }
}

.negative {
    color: $alert-color!important;
}

.positive {
    color: $success-3-color !important;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
    color: $primary-color;
}

.el-checkbox.is-bordered.is-checked,
.el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $primary-color;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: $primary-color;
}

.product-history-chart-choice-composite {
  display: flex;
  margin-top: 20px;
  height: 49px;
  width: 445px;
  border-radius: 12px;
  border: 1px solid var(--border);
  background: var(--background-color-2);

  button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      margin: 6px;
      font-weight: 600;
      font-size: 15px;
      width: 100%;
      background-color: transparent;
      color: var(--subtitle-text-color);

      img {
          margin-right: 5px;
      }
  }
}

.high-low-perf {
    border-radius: 12px;
    border: 1px solid var(--border);
    background: linear-gradient(180deg, $primary-color -258.33%, rgba(38, 103, 255, 0.00) 63.77%);
    margin-top: 20px;
    height: 138px;
    justify-content: space-around;

    .high .high-title, .low .low-title, .perf .perf-title {
        display: flex;
        color: var(--subtitle-2-text-color);
        margin-bottom: 29px;
        margin-top: 18px;
    }

    .high-price, .low-price, .perf-price {
      h5 {
          color: var(--text-color);
          font-size: 24px;
          font-weight: 600;
      }

      p {
          color: var(--subtitle-text-color);
          font-size: 14px;
          margin-top: 5px;
      }
    }
}

.chart-options {
    border-radius: 12px;
    border: 1px solid var(--border);
    background: var(--background-color-2);
    margin-top: 20px;
    padding: 20px;
    
    h4 {
        color: var(--text-color);
        margin-bottom: 25px;
        font-weight: 500;
    }

    .divider-chart-options {
      margin: 20px 0;
    }

    div {
        display: flex;
        color: var(--text-color);
        justify-content: space-between;
        align-items: center;

        h5 {
            font-size: 16px;
            font-weight: 600;
        }

        .select-currency {
          width: 75px;
          color: var(--text-color);

          div div div {
            padding-right: 0;
          }
        }

        div {
            margin-top: 3px;

            p {
                margin-right: 10px; 
            }
        }
    }
}
.product-history-chart-composite-data{
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: space-around;

  h4 {
    color: var(--text-color);
    margin-bottom: 10px;
    font-weight: 500;
  } 

  h5 {
    color: var(--text-color);
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  .identification {
    display: flex;
    margin-top: 10px;
    justify-content: center;

    .identification-code {
        cursor: pointer;
        color: var(--subtitle-2-text-color);
        font-weight: 500;
        font-size: 14px;
        padding: 3px 10px;
        border: 1px solid var(--border-4);
        border-radius: 6px;
    }
  }
}

.history-line-composite {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;

  .history-delay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--delay-color);

    .history-subscription-empty {
      display: flex;
      margin-right: 10px;

      img {
        margin-right: 8px;
      }
    
      .explore {
        align-items: flex-end;
        cursor: pointer;
        margin-left: 10px;
    
        h3 {
          color: var(--text-color);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }

  .composite-choice {
    align-items: center;
    justify-content: flex-end;

    p {
      color: var(--subtitle-text-color);
      font-size: 15px;
      font-weight: 500;
      margin-right: 15px;
    }
  }
}

.highcharts-yaxis-labels text {
  fill: $light-color !important;
}

.el-select-dropdown__item > span, .el-input__inner {
  color: $light-color !important;
}

.highcharts-navigator-xaxis>text {
  fill: transparent!important;
}

@media screen and (max-width: 1350px) {
 .hc-line-composite {
   padding: 17px 0px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   .hc {
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 17px;
      height: 543px;
      max-width: 850px;
      width: 100%;
      border-radius: 12px;
      border: 1px solid var(--border);
      background: var(--background-color-2);
      margin-bottom: 30px;
   }

   .product-history-infos-bloc {
      width: 100%;
      margin-left: 0;

      .product-history-chart-options-bloc {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .product-history-chart-choice-composite {
          display: none;
        }

        .high-low-perf, .chart-options, .product-history-chart-composite-data {
          margin-top: 0;
          width: 30%;

          .product-history-chart-composite-data-left, .product-history-chart-composite-data-right {

            h4 {
              text-align: center;
            }
          }
        }

        .high-low-perf {
          max-width: 220px;
        }
      }
    }
 }

 .history-line-composite {
  flex-direction: column;
  margin-left: 5px;
 }

 .history-subscription-empty {
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;

  .explore {
    margin-left: 0!important;
  }
 }

 .history-subscription-empty, .composite-choice {

  p {
    margin-left: 0!important;
  }
 }
}
</style>