<template>
  <div class="blocktrades-bottles">
    <div class="blocktrade-bottle" v-for="(bottle, b) in bottlesInfos" :key="b">
      <img
        v-if="bottle.files_attachments"
        :src="bottle.files_attachments[0]"
        class="spirit-type"
        height="58"
        width="58"
      />

      <div class="bottle-description">
        <h3>{{ bottle.title }}</h3>

        <div>
          <span class="ticker">{{ bottle.ticker }}</span>
        </div>
      </div>
    </div>

    <el-divider />

    <div class="link-explain-bloc">
      <p class="link">{{ $t('product_page.orders.blocktrade_unique_link') }}</p>

      <div class="input-group">
        <input :value="'https://thexchangeplatform.io/product/order/informations?token=' + getCreateOrderDialog" readonly type="text" />

        <button class="btn-copied" @click="copyLink">
          <span v-if="!copied">{{ $t('commons.copy_1') }}</span>
          <span v-if="copied">{{ $t('commons.copy_2') }}</span>
          <img
            v-if="copied"
            alt="Checked"
            class="checked themed-img"
            src="@/assets/svg/v2/checked.svg"
          />
        </button>
      </div>
      <div>
        <p class="share-info">
          {{ $t('product_page.orders.blocktrade_countdown') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    bottlesInfos: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      copied: false
    }
  },
  computed: {
    ...mapGetters({
      getCreateOrderDialog: 'getCreateOrderDialog'
    }),
  },
  methods: {
    ...mapActions({
      agreeOrder: 'agreeOrder',
      getClient: 'getAccountDetails'
    }),
    closeBlockTrade() {
      this.$store.commit('CREATE_ORDER_DIALOG', null)
    },
    copyLink() {
      if (navigator.clipboard) {

        navigator.clipboard.writeText('https://thexchangeplatform.io/product/order/informations?token=' + this.getCreateOrderDialog).then(() => {
          this.$emit('link-copied')
          this.copied = true
        })

      } else {
        const el = document.createElement('textarea');
        el.value = this.url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    },
    async acceptConditions() {
      const payload = {
        type: this.type,
      }

      await this.agreeOrder(payload).then(() => {
        this.getClient()
        this.$emit('agree', true)
      }).catch((error) => {
        if (error.response.status === 406) {
          this.$router.push('/register');
        }
      })
    },
    getPhoto(photo) {
      return require(photo)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.blocktrades-bottles {

  .blocktrade-bottle {
    display: flex;
    margin-bottom: 10px;

    img {
      border-radius: 3px;
    }

    .bottle-description {
      margin-left: 8px;

      h3 {
        font-weight: 500;
        font-size: 17px;
      }

      div {

        .ticker {
          background: transparent;
          border: 1px solid var(--border-grey);
          padding: 2px 6px;
          border-radius: 5px;
          color: var(--text-color);
          font-size: 14px;
        }
      }
    }
  }
}

.btn-copied {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 14px;
  border-radius: 15px;
  background: $primary-color;
  color: var(--text-color);
  border: none;
  cursor: pointer;
}

p.link {
  margin-bottom: 20px;
  color: var(--text-color) !important;
}

.share-info {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 15px;
  color: var(--text-color);
}

.link-explain-bloc {
  margin-top: 15px;
}

.input-group {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10px;

  input {
    width: 100%;
  }
}

.checked {
  margin-left: 5px;
}

@media (max-width: 768px) {
  .ref-blocktrade {
    align-items: center;
  }

  .btn-copied {
    width: auto;
    margin-left: 0;
    padding: 10px 15px;
  }

  .link-explain-bloc {
    padding: 0 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 500px) {

  .input-group {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  div.blocktrade {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .link, .share-info {
    font-size: 14px;
  }

  .share-info {
    text-align: center;
    margin-bottom: 0!important;
  }

  .btn-copied {
    padding: 8px 10px;
  }

  .input-group {
    width: 100%;
  }

  .link {
    margin-bottom: 0!important;
  }

  .link-explain-bloc {
    padding: 0;
  }
}
</style>